.default-font-size {
  font-size: 0.9em !important;
}
.item-description {
  font-size: 0.9em !important;
  padding-left: 0.8em !important;
}
.custom-card-header {
  padding: 0.8em !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  letter-spacing: 0.5px !important;
}