.default-font-size {
  font-size: 0.9em !important;
}
.item-description {
  font-size: 0.9em !important;
  padding-left: 0.8em !important;
}
.custom-badge {
  font-size: 0.9em !important;
  margin-right: 4px !important;
  border: 1px solid #d1d5db !important;
}
.custom-card-header {
  padding: 0.8em !important;
  font-size: 1em !important;
  font-weight: 700 !important;
  letter-spacing: 0.5px !important;
}
.custom-card-body {
  padding: 0.3em !important;
  padding-top: 0.5em !important;
}